<template>
  <div class="home">
    <section class="hero">
      <div class="hero-section">
        <div class="primary--text f56">{{ $t('home.hero.title') }}</div>
        <div class="f18 font-weight-medium gray--text mt-4">{{ $t('home.hero.desc') }}</div>
        <div class="d-sm-flex d-none flex-md-row flex-column mt-md-14 mt-10">
          <a
            href="https://apps.apple.com/us/app/snobar/id1613143471"
            target="_blank"
            class="d-block me-2"
            :style="`height: ${width > 960 ? 56 : 48}px`"
          >
            <img
              width="auto"
              :height="width > 960 ? 56 : 48"
              :src="require(`@/assets/icon/apple${select.key == 'ar' ? '-ar' : ''}.png`)"
              alt="apple"
              :srcset="getImgSet(select.key == 'ar' ? 'apple-ar' : 'apple')"
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=app.snobar.client"
            target="_blank"
            class="d-block ms-md-2 mt-md-0 mt-4"
            :style="`height: ${width > 960 ? 56 : 48}px`"
          >
            <img
              width="auto"
              :height="width > 960 ? 56 : 48"
              :src="require(`@/assets/icon/google${select.key == 'ar' ? '-ar' : ''}.png`)"
              alt="google"
              :srcset="getImgSet(select.key == 'ar' ? 'google-ar' : 'google')"
            />
          </a>
        </div>
        <v-btn to="/feed" depressed class="primary rounded-lg d-sm-none mt-8 px-4" height="54">
          <span class="accent--text">{{ $t('btn.goToSnobar') }}</span>
        </v-btn>
      </div>
      <v-spacer></v-spacer>
      <img
        src="@/assets/img/iPhone.png"
        srcset="@/assets/img/iPhone-2x.png 2x, @/assets/img/iPhone-3x.png 3x"
        alt="iPhone"
        class="hero-img"
      />
      <div class="hero-section accent--text">
        <div class="f18 font-weight-medium mb-sm-0 mb-4">{{ $t('home.hero.credo') }}</div>
        <!-- <div class="text-md-start text-center mx-md-0 mx-4">
          <div class="font-weight-bold f38 mt-md-8">{{ counter.users_total }}</div>
          <div class="font-weight-bold text-uppercase text-sm-body-1 text-body-2">{{ $t('home.hero.users') }}</div>
        </div>
        <div class="text-md-start text-center">
          <div class="font-weight-bold f38 mt-md-8">{{ counter.cars_total }}</div>
          <div class="font-weight-bold text-uppercase text-sm-body-1 text-body-2">{{ $t('home.hero.cars') }}</div>
        </div> -->
      </div>
    </section>
    <section class="info">
      <div class="wrap-main pt-14 pb-16">
        <div class="primary--text f38 font-weight-bold">{{ $t('home.app.title') }}</div>
        <div class="gray--text f18 font-weight-medium" style="max-width: 540px">{{ $t('home.app.desc') }}</div>
        <v-row class="pt-10 pb-2">
          <v-col cols="12" md="4" v-for="(i, j) in $t('home.app.appCards')" :key="i.title">
            <v-card flat class="white app-card">
              <img v-if="appCards[j]" :src="require(`@/assets/img/${appCards[j]}.svg`)" :alt="appCards[j]" />
              <div class="ps-md-7 pe-5 app-content" :class="j % 2 ? 'me-md-0 me-auto' : 'ms-md-0 ms-auto'">
                <div class="primary--text text-h4 font-weight-bold">{{ i.title }}</div>
                <div class="gray--text f18 font-weight-medium mt-2">{{ i.desc }}</div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </section>
    <v-card flat tile class="img-section" :img="require(`@/assets/img/img-section.jpg`)"></v-card>
    <section class="info community">
      <div class="wrap-main pt-14 pb-16">
        <div class="primary--text f42 community-title">{{ $t('home.community.title') }}</div>
        <div class="d-flex flex-md-row flex-column-reverse align-center">
          <div>
            <v-card
              class="info community-card"
              flat
              v-for="(i, j) in $t('home.community.communityList')"
              :key="i.title"
              :class="j ? 'mt-8' : 'mt-md-14 mt-8'"
            >
              <div class="primary--text text-h4 font-weight-bold">{{ i.title }}</div>
              <div class="gray--text f18 font-weight-medium mt-2">{{ i.desc }}</div>
            </v-card>
          </div>
          <v-spacer></v-spacer>
          <img
            class="ms-md-7 mt-md-0 mt-8"
            src="@/assets/img/iPhones.png"
            srcset="@/assets/img/iPhones-2x.png 2x, @/assets/img/iPhones-3x.png 3x"
            alt="iPhones"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      appCards: ['touch', 'community', 'cars'],
    };
  },
  mounted() {
    this.$store.dispatch('getCounter');
  },
  methods: {
    getImgSet(img) {
      return `
        ${require(`@/assets/icon/${img}-2x.png`)} 2x,
        ${require(`@/assets/icon/${img}-3x.png`)} 3x
      `;
    },
  },
  computed: {
    counter() {
      return this.$store.getters.counter;
    },
    width() {
      return this.$vuetify.breakpoint.width;
    },
    select() {
      return this.$store.getters.langValue;
    },
  },
  destroyed() {
    this.$store.dispatch('setCounter');
  },
};
</script>

<style lang="scss">
.home {
  .hero {
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    height: calc(100vh - 72px);
    min-height: 540px;
    padding-left: 60px;
    padding-right: 60px;
    background: linear-gradient(to right, white 57%, var(--v-blue-base) 43%);
    .hero-section {
      max-width: 500px;
    }
    .hero-section:last-of-type {
      max-width: 334px;
    }
    .hero-img {
      height: 40vw;
      position: absolute;
      left: 45%;
      top: 50%;
      transform: translate(0, -50%);
    }
  }
  .app-card {
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    border-radius: 16px !important;
    overflow: hidden;
    max-width: 424px;
    min-height: 500px;
    img {
      position: relative;
      left: 50%;
      transform: translate(-50%, 0);
    }
    .app-content {
      margin-top: 16px;
      min-height: 170px;
    }
  }
  .img-section {
    background-position-y: -120px !important;
    height: 65vh;
  }
  .community {
    .community-title {
      max-width: 720px;
    }
    .community-card {
      max-width: 645px;
    }
  }
}
.v-application--is-rtl {
  .home {
    .hero {
      background: linear-gradient(to left, white 57%, var(--v-blue-base) 43%);
      .hero-img {
        left: 30%;
      }
    }
  }
}
@media (min-aspect-ratio: 11/5) {
  .home {
    .hero {
      .hero-img {
        height: 28vw;
      }
    }
  }
}
@media screen and (max-width: 1440px) {
  .home {
    .hero {
      .hero-img {
        left: 42%;
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  .home {
    .hero {
      .hero-section {
        max-width: 440px;
      }
    }
    .img-section {
      background-position-y: -80px !important;
      height: 534px;
    }
    .community {
      .community-card {
        max-width: 600px;
      }
    }
  }
}
@media screen and (max-width: 959px) {
  .home {
    .f18 {
      font-size: 16px;
    }
    .text-h4 {
      font-size: 28px !important;
    }
    .f38,
    .f42 {
      font-size: 32px;
    }
    .f56 {
      font-size: 44px;
    }
    .hero {
      padding: 0;
      flex-direction: column;
      align-items: start;
      min-height: 770px;
      background: white;
      .hero-img {
        height: 52vw;
        left: 65%;
        top: 30%;
        transform: translate(0, -30%);
      }
      .hero-section {
        max-width: 400px;
        padding-left: 24px;
        padding-right: 24px;
        min-height: 550px;
        position: relative;
        top: 30%;
        transform: translate(0, -30%);
      }
      .hero-section:last-of-type {
        position: initial;
        transform: initial;
        width: 100%;
        min-height: 220px;
        display: flex;
        justify-content: space-between;
        padding-top: 56px;
        padding-bottom: 68px;
        background: var(--v-blue-base);
        max-width: 100%;
        div:first-of-type {
          max-width: 420px;
        }
        div:not(:first-of-type) {
          max-width: max-content;
          width: 100%;
        }
      }
    }
    .app-card {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-radius: 16px !important;
      overflow: hidden;
      max-width: 100%;
      min-height: 264px;
      img {
        margin: 0 auto;
        position: initial;
        transform: initial;
      }
      .app-content {
        // padding: 0 !important;
        max-width: 320px;
        min-height: initial;
      }
    }
    .col-12:nth-of-type(2n) .app-card {
      flex-direction: row-reverse !important;
      .app-content {
        padding-left: 20px !important;
      }
    }
    .img-section {
      background-position-y: -60px !important;
      height: 400px;
    }
    .community {
      .community-title {
        max-width: 100%;
        text-align: center;
      }
      .community-card {
        max-width: 100%;
      }
    }
  }
  .v-application--is-rtl {
    .home {
      .hero {
        background: white;
        .hero-img {
          left: 8%;
        }
      }
      .col-12:nth-of-type(2n) .app-card {
        .app-content {
          padding-right: 20px !important;
          padding-left: initial !important;
        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .home {
    .app-card {
      padding: 16px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      text-align: center;
      img {
        margin: 0 auto;
        position: initial;
        left: initial;
        transform: initial;
      }
      .app-content {
        margin: 20px 0 0 !important;
        padding: 0 !important;
      }
    }
    .col-12:nth-of-type(2n) .app-card {
      flex-direction: column !important;
      .app-content {
        padding-left: 0 !important;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .home {
    .text-h4 {
      font-size: 24px !important;
    }
    .f38,
    .f42 {
      font-size: 28px;
    }
    .f56 {
      font-size: 36px;
    }
    .hero {
      height: inherit;
      min-height: inherit;
      background: white;
      .hero-img {
        margin-top: 40px;
        position: relative;
        top: initial;
        left: 25%;
        transform: initial;
        height: 410px;
      }
      .hero-section {
        max-width: 100%;
        padding-left: 24px;
        padding-right: 24px;
        min-height: initial;
        position: initial;
        transform: initial;
        text-align: center;
      }
      .hero-section:last-of-type {
        justify-content: space-around;
        flex-wrap: wrap;
        min-height: initial;
        padding-top: 24px;
        padding-bottom: 26px;
        background: var(--v-blue-base);
        div:first-of-type {
          max-width: 100%;
        }
      }
    }
    .img-section {
      background-position-y: inherit !important;
      height: 340px;
    }
    .app-card,
    .community {
      img {
        width: 99%;
      }
    }
  }
  .v-application--is-rtl {
    .home {
      .hero {
        .hero-img {
          left: inherit;
          right: -25%;
        }
      }
    }
  }
}
@media screen and (max-width: 400px) {
  .home {
    .app-card {
      .app-content {
        padding: 0 12px !important;
      }
    }
  }
}
</style>
